import { ApiExplorerPage, apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {SearchPage, searchPlugin} from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TabsConfig,
  TechDocsReaderPage,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import { ExpandableNavigation, LightBox, ReportIssue, TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis } from './apis';
import { Root } from './components/Root';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';

import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import {discoveryApiRef, IdentityApi, microsoftAuthApiRef, useApi, gitlabAuthApiRef} from '@backstage/core-plugin-api';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { TechDocsCustomHome } from '@backstage/plugin-techdocs';
import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';
import {
    InsightsPage,
    insightsPlugin,
    InsightsSurveyLoader,
} from '@spotify/backstage-plugin-insights';
import { EntityTeamMetricsCard } from '@internal/plugin-metrics-viewer';
import { RBACRoot } from '@spotify/backstage-plugin-rbac';
import {setTokenCookie} from './cookieAuth';
import * as plugins from './plugins';


const microsoftAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message:
    'Sign in to Backstage Application using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

const gitlabAuthProvider: SignInProviderConfig = {
    id: 'gitlab-auth-provider',
    title: 'Gitlab',
    message:
        'Sign in to Backstage Application using your GitLab account.',
    apiRef: gitlabAuthApiRef,
};

const techDocsTabsConfig: TabsConfig = [
  {
    label: 'Recommended Documentation',
    panels: [
      {
        title: 'Golden Path',
        description:
          'The Golden Path is the opinionated and supported path to build integrations',
        panelType: 'DocsCardGrid',
        filterPredicate: entity =>
          entity?.metadata?.tags?.includes('recommended') ?? false,
      },
    ],
  },
  {
    label: 'Team documentation',
    panels: [
      {
        title: 'Team documentation',
        description: 'Any team specific documentation',
        panelType: 'DocsCardGrid',
        filterPredicate: entity =>
          (entity?.metadata?.tags?.includes('team-docs') ?? false) ||
          entity?.metadata?.type === 'docs',
      },
    ],
  },
  {
    label: 'Libraries',
    panels: [
      {
        title: 'Libraries',
        description: 'Documentation for all our shared libraries',
        panelType: 'DocsCardGrid',
        filterPredicate: entity => entity?.spec?.type === 'library' && entity?.spec?.owner === "default/intropy",
      },
    ],
  },
];

const app = createApp({
  apis,
    plugins: Object.values(plugins),
  components: {
      SignInPage: props => {
          const discoveryApi = useApi(discoveryApiRef);
          return (
              <SignInPage {...props} auto providers={[microsoftAuthProvider, gitlabAuthProvider]}
                          onSignInSuccess={async (identityApi: IdentityApi) => {
                              await setTokenCookie(
                                  await discoveryApi.getBaseUrl('cookie'),
                                  identityApi,
                              );

                              props.onSignInSuccess(identityApi);
                          }}
              />
          );
      },
  },

    bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
        bind(insightsPlugin.externalRoutes, {
            searchPage: searchPlugin.routes.root,
        });
  },
});

// const AppProvider = app.getProvider();
// const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage initialKind="system"/>} />
      <Route path="/insights" element={<InsightsPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/docs"
      element={<TechDocsCustomHome tabsConfig={techDocsTabsConfig} />}
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
          <ExpandableNavigation />
          <LightBox />
          <ReportIssue />
          <TextSize />
          <Mermaid />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} pageTitle="Integrio Tech Radar"/>}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
      <Route
          path='/soundcheck'
          element={<SoundcheckRoutingPage title='Integration Scoring' />}
      />
      <Route path="/metrics-viewer" element={<EntityTeamMetricsCard />} />
      <Route path="/rbac" element={<RBACRoot />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
      <AppRouter>
        <InsightsSurveyLoader />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
