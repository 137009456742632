import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isKind,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
    ExpandableNavigation,
    LightBox,
    ReportIssue,
    TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid'
import {  Grid } from '@material-ui/core';
import React, { ReactNode, useMemo, useState } from 'react';

import { EntityAdrContent, isAdrAvailable } from '@backstage-community/plugin-adr';
import {
    Direction,
    EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
    RELATION_API_CONSUMED_BY,
    RELATION_API_PROVIDED_BY,
    RELATION_CONSUMES_API,
    RELATION_DEPENDENCY_OF,
    RELATION_DEPENDS_ON,
    RELATION_HAS_PART,
    RELATION_PART_OF,
    RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { EntityRevisionContent } from '@revisionapp/backstage-revision-plugin';
import {
    EntitySoundcheckContent,
    EntitySoundcheckCard,
} from '@spotify/backstage-plugin-soundcheck';
import { GroupSoundcheckContent } from '@spotify/backstage-plugin-soundcheck';
import {EntityTeamMetricsCard, ContentMtbfMetrics} from "@internal/plugin-metrics-viewer";
import BadgeIcon from '@material-ui/icons/CallToAction';
import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import {
    isGitlabAvailable,
    EntityGitlabContent,
} from '@immobiliarelabs/backstage-plugin-gitlab';

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
    const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

    const extraMenuItems = useMemo(() => {
        return [
            {
                title: 'Badges',
                Icon: BadgeIcon,
                onClick: () => setBadgesDialogOpen(true),
            },
        ];
    }, []);

    return (
        <>
            <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
                {props.children}
            </EntityLayout>
            <EntityBadgesDialog
                open={badgesDialogOpen}
                onClose={() => setBadgesDialogOpen(false)}
            />
        </>
    );
};


const techdocsContentWithAddons = (
    <EntityTechdocsContent>
        <TechDocsAddons>
            <ExpandableNavigation />
            <LightBox />
            <ReportIssue />
            <TextSize />
            <Mermaid />
        </TechDocsAddons>
    </EntityTechdocsContent>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
      <Grid item md={6} xs={12}>
          <EntitySoundcheckCard/>
      </Grid>
    <Grid item md={12} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
  </Grid>
);

const cicdContent = (
    <EntitySwitch>
        <EntitySwitch.Case if={isGitlabAvailable}>
            <EntityGitlabContent/>
        </EntitySwitch.Case>
    </EntitySwitch>
);


/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContentWithAddons}
    </EntityLayout.Route>

    <EntityLayout.Route path="/revision-diagram" title="Diagram">
      <EntityRevisionContent />
    </EntityLayout.Route>

  </EntityLayoutWrapper>
);


const componentPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/api" title="API">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <EntityProvidedApisCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityConsumedApisCard />
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem" />
                </Grid>
            </Grid>
        </EntityLayout.Route>
        
        

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContentWithAddons}
        </EntityLayout.Route>

        <EntityLayout.Route path="/revision-diagram" title="Diagram">
            <EntityRevisionContent />
        </EntityLayout.Route>

        <EntityLayout.Route path="/diagram" title="Relations">
            <EntityCatalogGraphCard
                variant="gridItem"
                direction={Direction.TOP_BOTTOM}
                title="System Diagram"
                height={700}
                relations={[
                    RELATION_PART_OF,
                    RELATION_HAS_PART,
                    RELATION_API_CONSUMED_BY,
                    RELATION_API_PROVIDED_BY,
                    RELATION_CONSUMES_API,
                    RELATION_PROVIDES_API,
                    RELATION_DEPENDENCY_OF,
                    RELATION_DEPENDS_ON,
                ]}
                unidirectional={false}
            />
        </EntityLayout.Route>
        <EntityLayout.Route path="/ci-cd" title="CI/CD">
            {cicdContent}
        </EntityLayout.Route>
        <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
            <EntityAdrContent />
        </EntityLayout.Route>

    </EntityLayoutWrapper>
);

const apiPage = (
    
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
      </EntityLayoutWrapper>
);

const userPage = (
    <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
          <Grid item xs={12} md={6}>
              <EntityTeamMetricsCard/>
          </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
          
      </Grid>
    </EntityLayout.Route>
      <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
          <GroupSoundcheckContent />
      </EntityLayout.Route>
      <EntityLayout.Route path="/metrics-viewer" title="MTBF">
          <ContentMtbfMetrics />
      </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
          <Grid item md={6} xs={12}>
              <EntitySoundcheckCard/>
          </Grid>
          <Grid item md={12}>
              <EntityHasApisCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
              <EntityHasComponentsCard variant="gridItem" />
          </Grid>
        
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
          
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/revision-diagram" title="Diagram">
      <EntityRevisionContent />
    </EntityLayout.Route>
      <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
          <EntitySoundcheckContent />
      </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Relations">
          <EntityCatalogGraphCard
              variant="gridItem"
              direction={Direction.TOP_BOTTOM}
              title="System Diagram"
              height={700}
              relations={[
                  RELATION_PART_OF,
                  RELATION_HAS_PART,
                  RELATION_API_CONSUMED_BY,
                  RELATION_API_PROVIDED_BY,
                  RELATION_CONSUMES_API,
                  RELATION_PROVIDES_API,
                  RELATION_DEPENDENCY_OF,
                  RELATION_DEPENDS_ON,
              ]}
              unidirectional={false}
          />
      </EntityLayout.Route>
      <EntityLayout.Route path="/ci-cd" title="CI/CD">
          {cicdContent}
      </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const resourcePage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3} alignItems="stretch">
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityAboutCard variant="gridItem" />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                    <EntityLinksCard />
                </Grid>
            </Grid>
        </EntityLayout.Route>
        <EntityLayout.Route path="/diagram" title="Relations">
            <EntityCatalogGraphCard
                variant="gridItem"
                direction={Direction.TOP_BOTTOM}
                title="System Diagram"
                height={700}
                relations={[
                    RELATION_PART_OF,
                    RELATION_HAS_PART,
                    RELATION_API_CONSUMED_BY,
                    RELATION_API_PROVIDED_BY,
                    RELATION_CONSUMES_API,
                    RELATION_PROVIDES_API,
                    RELATION_DEPENDENCY_OF,
                    RELATION_DEPENDS_ON,
                ]}
                unidirectional={false}
            />
        </EntityLayout.Route>

    </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/revision-diagram" title="Diagram">
        <EntityRevisionContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
      <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
